import React from 'react';
import { Link } from 'gatsby';

import '../styles/privacy-policy.scss';
import HomeLayout from '../blocks/layout/home';
import SEO from '../blocks/layout/seo';

const PrivacyPolicy = () => (
    <HomeLayout>
        <SEO title="Privacy & Policy" keywords={[]} />
        <div>
            <div className="privacy-policy">
                <div className="privacy-content">
                    <div className="tag">LEGAL</div>
                    <h2>
                        <strong>Terms and Conditions</strong>
                    </h2>

                    <p>Welcome to Form with Sheet!</p>

                    <p>
                        These terms and conditions outline the rules and
                        regulations for the use of Form with Sheet is Website,
                        located at https://formwithsheet.com.
                    </p>

                    <p>
                        By accessing this website we assume you accept these
                        terms and conditions. Do not continue to use Form with
                        Sheet if you do not agree to take all of the terms and
                        conditions stated on this page.
                    </p>

                    <p>
                        The following terminology applies to these Terms and
                        Conditions, Privacy Statement and Disclaimer Notice and
                        all Agreements: "Client", "You" and "Your" refers to
                        you, the person log on this website and compliant to the
                        Company’s terms and conditions. "The Company",
                        "Ourselves", "We", "Our" and "Us", refers to our
                        Company. "Party", "Parties", or "Us", refers to both the
                        Client and ourselves. All terms refer to the offer,
                        acceptance and consideration of payment necessary to
                        undertake the process of our assistance to the Client in
                        the most appropriate manner for the express purpose of
                        meeting the Client’s needs in respect of provision of
                        the Company’s stated services, in accordance with and
                        subject to, prevailing law of Netherlands. Any use of
                        the above terminology or other words in the singular,
                        plural, capitalization and/or he/she or they, are taken
                        as interchangeable and therefore as referring to same.
                    </p>

                    <h3>
                        <strong>License</strong>
                    </h3>

                    <p>
                        Unless otherwise stated, Form with Sheet and/or its
                        licensors own the intellectual property rights for all
                        material on Form with Sheet. All intellectual property
                        rights are reserved. You may access this from Import
                        Data for your own personal use subjected to restrictions
                        set in these terms and conditions.
                    </p>

                    <p>You must not:</p>
                    <ul>
                        <li>Republish material from Form with Sheet</li>
                        <li>
                            Sell, rent or sub-license material from Form with
                            Sheet
                        </li>
                        <li>
                            Reproduce, duplicate or copy material from Form with
                            Sheet
                        </li>
                        <li>Redistribute content from Form with Sheet</li>
                    </ul>

                    <h3>
                        <strong>Hyperlinking</strong>
                    </h3>

                    <p>
                        Form with Sheet has not reviewed all of the sites linked
                        to its website and is not responsible for the contents
                        of any such linked site. The inclusion of any link does
                        not imply endorsement by Form with Sheet of the site.
                        Use of any such linked website is at the user's own
                        risk.
                    </p>
                    <h3>
                        <strong>iFrames</strong>
                    </h3>

                    <p>
                        Without prior approval and written permission, you may
                        not create frames around our Webpages that alter in any
                        way the visual presentation or appearance of our
                        Website.
                    </p>

                    <h3>
                        <strong>Refunds</strong>
                    </h3>

                    <p>
                        There is no refund policy, we suggest to use free
                        subscription until you are not satisfied with the
                        service.
                    </p>

                    <h3>
                        <strong>Reservation of Rights</strong>
                    </h3>

                    <p>
                        We reserve the right to request that you remove all
                        links or any particular link to our Website. You approve
                        to immediately remove all links to our Website upon
                        request. We also reserve the right to amen these terms
                        and conditions and it’s linking{' '}
                        <Link to="/privacy-policy">policy</Link> at any time.
                    </p>

                    <h3>
                        <strong>Limitations</strong>
                    </h3>

                    <p>
                        In no event shall Form with Sheet or its suppliers be
                        liable for any damages (including, without limitation,
                        damages for loss of data or profit, or due to business
                        interruption) arising out of the use or inability to use
                        the materials on Form with Sheet's website, even if Form
                        with Sheet or a Form with Sheet authorized
                        representative has been notified orally or in writing of
                        the possibility of such damage. Because some
                        jurisdictions do not allow limitations on implied
                        warranties, or limitations of liability for
                        consequential or incidental damages, these limitations
                        may not apply to you.
                    </p>

                    <h3>
                        <strong>Third parties terms</strong>
                    </h3>
                    <ul>
                        <li>
                            <a
                                href="https://www.youtube.com/t/terms"
                                target="_blank"
                            >
                                YouTube Terms Page
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://developers.google.com/termss"
                                target="_blank"
                            >
                                Google APIs Terms of Service
                            </a>
                        </li>
                        <li>
                            <a href="https://airtable.com/tos" target="_blank">
                                Airtable Terms of Service
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://docs.github.com/en/github/site-policy/github-terms-of-service"
                                target="_blank"
                            >
                                GitHub Terms of Service
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </HomeLayout>
);

export default PrivacyPolicy;
